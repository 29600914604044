/**
 * Created with JetBrains PhpStorm.
 * User: gschulz
 * Date: 12.08.13
 * Time: 13:39
 */

let Media = (function () {

    let _private = {};

    _private.form = $();

    _private.successCallback = null;

    _private.deleteCallback = null;

    _private.instance = this;

    _private.fileAccept = {
        '_db$':               '*',
        '_map$':              '*',
        'document|checklist': 'application/pdf',
        'image':              'image/*',
    };

    this.init = function (form, fromUploadTool = false) {
        form          = $(form);
        _private.form = form;
        _private.initForm(fromUploadTool);
        if (!fromUploadTool) {
            let objectId = form.find('input[name=object_id]').pVal();
            if (parseInt(objectId) > 0) {
                let contextMenuOptions          = {};
                contextMenuOptions.delete_media = {
                    params:    {object_id: objectId},
                    click:     new Abstract().deleteObject,
                    clickArgs: [form, 'Media']
                };
                ContextMenu.setContentMenu(contextMenuOptions);
                ContextMenu.init();
            }
        } else {
            form.find('a.delete').unbind('click.deleteMedia').on('click.deleteMedia',
                function (event) {
                    new Abstract().deleteObject(form, 'Media', event, _private.deleteCallback);
                }
            );
        }
    };

    _private.initForm = function (fromUploadTool) {
        _private.initFileElements(_private.form);
        new EfbHelperForm().initDatePicker(_private.form);
        let observer = new FormObserver().init(_private.form);
        if ($(_private.form).find('a.submit').length) {
            observer.setSaveButton(_private.form.find('a.submit'));
            $(_private.form).find('a.submit').unbind('click.submit').on('click.submit',
                function (event) {
                    event.preventDefault();
                    $(_private.form).submit();
                }
            ).addClass('disabled');
            $(_private.form).find('button.reset').unbind('click.reset').on('click.reset',
                function (event) {
                    event.preventDefault();
                    $(_private.form).find('input').val('');
                    $(_private.form).find('fieldset.media-link').remove();
                }
            );
        } else {
            if (!fromUploadTool) {
                observer.setSaveCallBack('submit',
                    function () {
                        $(_private.form).submit();
                    }
                );
            }
        }
        new EfbHelperForm().initChosenElements($(_private.form).find('select.chosen'));
        $(_private.form).find('select[name=media_type]').unbind('change.updateFileAccept').on('change.updateFileAccept', e => {
            let type   = $(e.target).val();
            let key    = Object.keys(_private.fileAccept).find(e => {
                return new RegExp(e).test(type);
            });
            let accept = _private.fileAccept[key];
            _private.form.find('input[type=file]').prop('accept', accept)
        }).trigger('change');

        $(_private.form).ajaxForm({
            dataType:       'json',
            beforeSend:     function () {
                let percentVal = '0%';
                $(_private.form).find('.bar').css('width', percentVal);
                $(_private.form).find('.percent').html(percentVal);
                EfbHelper.addLoadingSpinner($('body'));
            },
            uploadProgress: function (event, position, total, percentComplete) {
                let percentVal = percentComplete + '%';
                $(_private.form).find('.bar').css('width', percentVal);
                $(_private.form).find('.percent').html(percentVal);
            },
            complete:       function (xhr) {
                let response = $.parseJSON(xhr.responseText);
                EfbHelper.removeLoadingSpinner($('body'));
                let formParent = $(_private.form).parent();
                // this is a dirty IE hotfix
                response.html  = response.html.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
                let form       = $(response.html);
                _private.form.replaceWith(form);
                _private.form = form;
                _private.instance.init($(formParent).find('form'), fromUploadTool);
                if (_.isFunction(_private.successCallback)) {
                    _private.successCallback.apply(this, [response]);
                }
                let formStatus = new FormStatus().init();
                if (response.status === 'save_ok') {
                    formStatus.addStatus('save_ok', 'media', '', _private.form);
                    $(_private.form).find('.bar').css('width', '100%');
                    $(_private.form).find('.percent').html('100%');
                } else {
                    formStatus.addStatus('save_error', 'media', '', _private.form);
                    $(_private.form).find('.bar').css('width', '0%');
                    $(_private.form).find('.percent').html('0%');
                }
                if (!response['from_upload_tool'] && response['redirect']) {
                    setTimeout(function () {
                        location.href = response['redirect'];
                    }, 1000);
                }
            }
        });
    };

    _private.initFileElements = function (element) {
        let fileElements = $(element).find('div.formelement.file input[type=file]');
        $(element).find('div.formelement.file div.filename,div.formelement.file button')
                  .unbind('click.fileLink').on('click.fileLink',
            function (event) {
                event.preventDefault();
                $(event.target).parent('div.formelement').find('input[type=file]').click();
            }
        );
        $(fileElements).unbind('change.updateFileElement').on('change.updateFileElement',
            function (event) {
                let form              = $(event.target).parents('form');
                let uploadMaxFileSize = null;
                if (form.find('input[name=upload_max_filesize]').length) {
                    uploadMaxFileSize = form.find('input[name=upload_max_filesize]').pVal();
                }
                let humanFileSizeElement = form.find('input[name=file_size_human]');
                if (humanFileSizeElement.length) {
                    let browser = EfbHelper.userAgent().browser;
                    let bytes   = 0;
                    if (browser.name === 'IE' && parseInt(browser.major) <= 11) {
                        bytes = humanFileSizeElement - 1;
                    } else {
                        bytes        = this.files[0].size;
                        let fileSize = EfbHelper.formatFileSize(bytes, 0);
                        humanFileSizeElement.val(fileSize);
                        if (bytes > uploadMaxFileSize) {
                            Validator.addError(humanFileSizeElement, 'uploadMaxFileSize',
                                EfbHelper.formatFileSize(uploadMaxFileSize, 2));
                        }
                    }
                    form.find('input[name=size]').val(bytes).keyup();
                }
                let value = $(event.target).val().replace(/C:\\fakepath\\/, '');
                $(event.target).parents('div.formelement').find('div.filename').text(value);
            }
        );
    };

    this.setSuccessCallback = function (successCallback) {
        _private.successCallback = successCallback;
    };

    this.setDeleteCallback = function (deleteCallback) {
        _private.deleteCallback = deleteCallback;
    };
});
